/* Your custom styles */

*{margin:0;}



#overlay{
  position:fixed;
  z-index:99999;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background:rgba(0,0,0,1);
  background-image: url('../img/bthope2.png');
  background-repeat: no-repeat;
  background-size: 25%;
  background-position: center;
  transition: 1s 0.4s;
  overflow: hidden;
}
#progress{
  height:3px;
  background:#fff;
  position:absolute;
  width:0;
  top:50%;
}
#progstat{
  font-size:0.7em;
  letter-spacing: 3px;
  position:absolute;
  top:50%;
  margin-top:-40px;
  width:100%;
  text-align:center;
  color:#fff;

}

#firstnameform {
    display: none;
}
#mdb-preloader.loaded {
    opacity: 0;
    transition: 0.3s ease-in 1s;
  }

#lglogo {
    margin-left: auto;
    margin-right: auto;
    margin-top: 350px;
    width: 40%;
    height: 40%;
    justify-content: center;
}

#lameload {
    background-color: black;
    background-size: cover;
    text-align: center;
}

#nopic {
    background-image: none;
}

@media (min-width: 768px) {
    .carousel-multi-item-2 .col-md-3 {
        float: left;
        width: 25%;
        max-width: 100%;
    }
}

.carousel-multi-item-2 .card img {
    border-radius: 2px;
}

#descript {
    text-align: center;
    font-style: italic
}

#ytframe {
    width: 100%;
}
