.App {
  text-align: center;
  overflow-x: hidden;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.lame-title {
  color: white;
}

.lame-jumbotron {
  position: relative; /* Make the container relative for positioning pseudo-element */
  min-height: 100vh;

}

.main {
  background: url('./img/lggood.jpg') no-repeat center center fixed;
  background-size: cover;
}

.music {
  background: url('./img/mag.jpg') no-repeat center center fixed;
  background-size: cover;
}

.about {
  background: url('./img/mag2.jpg') no-repeat center center fixed;
  background-size: cover;
}

.band {
  background: url('./img/mag3.jpg') no-repeat center center fixed;
  background-size: cover;
}

.contact {
  background: url('./img/lgpromo.png') no-repeat center center fixed;
  background-size: cover;
  background-position: center;
}

.merch {
  background: url('./img/tmg.jpg') no-repeat center center fixed;
  background-size: cover;
}

.news {
  background: url('./img/tmg2.jpg') no-repeat center center fixed;
  background-size: cover;
}

.news h2,
.news h3,
.news h4,
.news h5,
.tour h5 {
  text-align: left !important;
  font-weight: bold !important;
}

.tour {
  background: url('./img/tacofloor.jpg') no-repeat center center fixed;
  background-size: cover;
  background-position: center;
}

.videos {
  background: url('./img/taco1.jpg') no-repeat center center fixed;
  background-size: cover;
}

.lame-jumbotron::before {
  content: ""; /* Create a pseudo-element for the gradient overlay */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(9, 8, 32, 0.85), rgba(0, 0, 0, 0.85));
  z-index: 0; /* Set a lower z-index for the overlay */
}

.news-item {
  background: rgba(255, 255, 255, 0.25);
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}
.tour-item {
  background: rgba(255, 255, 255, 0.25);
  border: 2px solid black;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.tour-item:hover {
  transform: scale(1.02);
  border: 2px solid white;

}

.loading-bar {
  transform: scale(1.2);
}
.lame-title {
  position: relative; /* Ensure text is within the stacking context */
  color: white;
  width: 100%;
  padding-top: 25vh;
}

.lame-content {
  position: relative; /* Ensure text is within the stacking context */
  color: white;
  width: 100%;
  padding-top: 70px;
}

.contact-form {
  z-index: 1;
}
p {
  text-align: left;
}
.taco {
  max-width: 30vw;
}
.snackbar {
  z-index: 1000;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Make text responsive */
@media screen and (max-width: 600px) {
  .news h5 {
    font-size: 3vw !important;
  }
  
  .news p {
    font-size: 2.25vw !important;
  }

  .tour-item,
  .news-item {
    margin: 10px 0px;
  }
}